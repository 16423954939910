import Api from "../Api"

export class ApiPay extends Api{
    constructor(){
        super()
    }

    async getClientes(id=-1){
        return await super.get("facturacion/clientes", id)
    }

    async getClientesP(params, id=-1){
        return await super.getParams("facturacion/clientes", id, params)
    }

    async saveClientes(obj){
        return await super.save("facturacion/clientes", obj)
    }

    async delClientes(id){
        return await super.delete("facturacion/clientes", id)
    }

    async getFacturas(id=-1){
        return await super.get("facturacion/facturas", id)
    }

    async saveFacturas(obj){
        return await super.save("facturacion/facturas", obj)
    }
    
    async getEmpleados(id=-1){
        return await super.get("nomina/empleados", id)
    }

    async getMetodosPagoP(params, id=-1){
        return await super.getParams("facturacion/metodos-pago", id, params)
    }

    async saveMetodosPago(obj){
        return await super.save("facturacion/metodos-pago", obj)
    }

    async getFacturasP(params, id=-1){
        return await super.getParams("facturacion/facturas", id, params)
    }

    async getFacturasPaginator(params, id=-1){
        return await super.getParamsPaginator("facturacion/facturas", id, params)
    }

    async getFacturasInf(params, id=-1){
        return await super.getParams("facturacion/facturas-informe", id, params)
    }

    async getExcelClientes(params){
        return await super.getFile("facturacion/informe-clientes-excel", params)
    }

    async getExcelFact(params){
        return await super.getFile("facturacion/informe-fact-excel", params)
    }
}