<template>
  <v-app>
    <v-row>
      <v-col>
        <v-data-table dense :headers="headers" :items="items" :search="search" :expanded.sync="expanded"
          :single-expand="true" class="elevation-1" hide-default-footer item-key="id" show-expand :loading="loading"
          :page.sync="page" :items-per-page="pages" loading-text="Cargando...">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Citas</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar"></v-text-field>
              <v-col cols="6">
                <v-container class="max-width">
                  <v-pagination v-model="page" :length="pageCount" circle></v-pagination>
                </v-container>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn color="warning" dark class="mb-2" @click="iniciar">
                <v-icon>cached</v-icon>
              </v-btn>

              <v-dialog v-model="dialog" max-width="100%" dense persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"><v-icon>add_box</v-icon></v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="2">
                          <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="date" label="Fecha Cita" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="date"
                              :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                              locale="es-co" @change="saveDate"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="2">
                          <v-menu ref="menuh1" v-model="menuh1" :close-on-content-click="false" :nudge-right="40"
                            :return-value.sync="time" transition="scale-transition" offset-y max-width="290px"
                            min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="time" label="Hora Cita" prepend-icon="mdi-clock-time-four-outline"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-time-picker v-if="menuh1" v-model="time" full-width
                              @click:minute="$refs.menuh1.save(time)"></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete v-model="editedItem.client" :items="itemsClients" label="Cliente"
                            item-text="identification" color="purple darken-2" return-object></v-autocomplete>
                        </v-col>
                        <v-col cols="4">
                          <v-autocomplete v-model="editedItem.allocator" :items="itemsEmployees" label="Quien asigna"
                            item-text="name" color="purple darken-2" return-object></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field v-model="editedItem.observation" label="Observaciones"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-data-table :headers="serviceHeaders" :items="services" hide-default-footer class="elevation-1">
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-toolbar-title>Servicios</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" class="mb-2" dark fab :disabled="!formIsValidService"
                              @click="pushService">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-toolbar>
                        </template>
                        <template v-slot:body.prepend>
                          <tr>
                            <td>
                              <v-text-field v-model="editedService.item" label="Item" disabled=""></v-text-field>
                            </td>
                            <td>
                              <v-autocomplete v-model="editedService.service" :items="itemsServices" label="Servicio"
                                item-text="name" color="purple darken-2" return-object></v-autocomplete>
                            </td>
                            <td>
                              <v-text-field v-model="editedService.service.sale_price" label="Valor"
                                readonly></v-text-field>
                            </td>
                            <td>
                              <v-autocomplete v-model="editedService.assigned" :items="itemsEmployees"
                                label="Profesional" item-text="name" color="purple darken-2"
                                return-object></v-autocomplete>
                            </td>
                          </tr>
                        </template>
                        <template v-slot:no-data>
                          Servicios
                        </template>
                        <template v-slot:item.service="{ item }">
                          {{ item.service.name }}
                        </template>
                        <template v-slot:item.assigned="{ item }">
                          {{ item.assigned.name }}
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon color="danger" small @click="deleteItem(item)">mdi-delete</v-icon>
                        </template>
                      </v-data-table>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-col cols="2" class="ms-auto">
                          <v-text-field v-model="editedItem.payment" label="Abono"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field v-model="editedItem.total" readonly label="Total"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="pink accent-3" :disabled="!formIsValid" text @click="save">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.appointment_date="{ item }">
            {{ item.appointment_date.split("T")[0] }}
          </template>
          <template v-slot:item.hour="{ item }">
            {{ item.appointment_date.split("T")[1].split("-")[0] }}
          </template>
          <template v-slot:item.observation="{ item }">
            <div style="max-width:150px">
              {{ item.observation }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
            <v-icon small class="mr-2" @click="completeItem(item)">mdi-check-circle-outline</v-icon>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table dense :headers="serviceExpHeaders" :items="item.services_date" hide-default-footer
                class="elevation-1">
              </v-data-table>
            </td>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="iniciar">Reiniciar</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { ApiBook } from "./ApiBook";
import { ApiPay } from "../billing/ApiPay";
import { ApiInv  } from "../inventory/ApiInv";

// import mensajesMixin from "../../../mixins/mensajesMixin"

export default {
  name: "RegistroCitas",
//   mixins:[mensajesMixin],
  data() {
    return {
      items: [],
      itemsClients: [],
      itemsEmployees: [],
      itemsServices: [],
      services:[],
      servicesSave:[],
      expanded: [],
      valueItem:1,
      abono:0,
      total:0,
      api: new ApiBook(),
      apiPay: new ApiPay(),
      apiInv: new ApiInv(),
      loading: false,
      search: "",
      time: null,
      menuh1: false,
      pages:-1,
      page:1,
			pageCount: 0,
			totalDates:0,
      menu: false,
      headers: [
        {
          text: "Fecha",
          align: "center",
          sortable: true,
          value: "appointment_date",
        },
        {
          text: "Hora",
          align: "center",
          sortable: true,
          value: "hour",
        },
        {
          text: "Cliente",
          align: "start",
          sortable: true,
          value: "client_description",
        },
        {
          text: "Telefono",
          align: "start",
          sortable: true,
          value: "client_phone",
        },
        {
          text: "Asignador",
          align: "start",
          sortable: true,
          value: "allocator_description",
        },
        {
          text: "Abono",
          align: "start",
          sortable: true,
          value: "payment",
        },
        {
          text: "Faltante",
          align: "start",
          sortable: true,
          value: "payable",
        },
        {
          text: "Total",
          align: "start",
          sortable: true,
          value: "total",
        },
        {
          text: "Observaciones",
          align: "start",
          sortable: false,
          value: "observation",
        },
        { text:'',value:'actions',sortable:false },
      ],
      serviceHeaders: [
        { text: "Item", value: "item", sortable:false },
        { text: "Servicio", value: "service.name", sortable:false },
        { text: "Valor", value: "service.sale_price", sortable:false},
        { text: "Profesional", value: "assigned.name", sortable:false },
        { text:'',value:'actions',sortable:false },
      ],
      serviceExpHeaders: [
        { text: "Item", value: "item", sortable:false },
        { text: "Servicio", value: "ser_description", sortable:false },
        { text: "Valor", value: "ser_price", sortable:false},
        { text: "Profesional", value: "assigned_description", sortable:false },
        { text:'',value:'actions',sortable:false },
      ],
      dialog:false,
      editedIdex:-1,
      editedItem:{
          id:-1,
          client:{"id":-1, value:""},
          allocator:{"id":-1, value:""},
          payment:0,
          observation:"",
          total:0,
      },
      defaultItem:{
          id:-1,
          client:{"id":-1, value:""},
          allocator:{"id":-1, value:""},
          payment:0,
          observation:"",
          total:0,
      },
      editedService:{
        service:{"id":-1, value:""},
        assigned: {"id":-1, value:""},
      },      
      defaultService:{
        service:{"id":-1, value:""},
        assigned: {"id":-1, value:""},
      },
      rules:{
        in_req: [val => (val || '').length > 0 || 'Requerido'],
      },
      booltypes:[
        {text:"SI", value:true},
        {text:"NO", value:false}
      ],
      date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    };
  },
  computed:{
      formTitle(){
          return (this.editedIdex === -1 ? "Nueva": "Editar") + " Cita"
      },
      formIsValid () {
        return (
          this.services.length > 0 &&
          this.editedItem.client.id !== -1
        )
      },      
      formIsValidService () {
        return (
          this.editedService.service.id !== -1 &&
          this.editedService.service["sale_price"] > 0 &&
          this.editedService.assigned.id !== -1
        )
      },   
  },
  watch:{
    dialog(val){
        val || this.close()
    },
    page(val) {
      this.iniciar(val);
    },
  },
  methods: {
    async iniciar() {
      try {
        this.loading = true;
        let params=`enterprise=${localStorage.getItem("SucursalId")}&page=${this.page}`
        let r = await this.api.getBookingPaginator(params);
        let rC = await this.apiPay.getClientesP(params);
        let rE = await this.apiPay.getEmpleados();
        let rS = await this.apiInv.getServiciosP(params);
        this.itemsClients = rC;
        this.itemsEmployees = rE;
        this.itemsServices = rS;
        this.items = r.results.filter(
          (x) => x.completed === false
        ); 
				this.totalDates = r.count; 
				this.pages = this.items.length
        console.log(this.pages);
				if (this.page === 1){
					this.pageCount = Math.ceil(this.totalDates / this.pages)
				}
      } catch (error) {
        alert(error);
        // this.mensaje(error,"Error","error")
        this.msgError(error)
      } finally {
        this.loading = false;
      }
    },
    saveDate (date) {
        this.$refs.menu.save(date)
      },
    close(){
        this.dialog = false
        this.services = []
        this.valueItem = 1
        this.total = 0
        this.time = null
        this.menuh1 = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedService = Object.assign({}, this.defaultService);
        });
    },
    editItem(item){
        this.editedIdex = this.items.indexOf(item)
        this.editedItem = Object.assign({},item)
        this.dialog = true
    },
    async completeItem(item) {
      try {
        this.loading = true
        this.$swal({
          title: '¿Está Seguro?',
          html: `Completar la cita del cliente <br> <b>${item.client_description} </b>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si, Completarla!',
          cancelButtonText: 'No, Mantenerla!',
          showCloseButton: true,
        }).then(async (result) => {
          if (result.value) {
            let r = await this.api.completeBooking(item)
            if (r.status === 500) {
              this.$swal("Error", "No se puede completar la cita del cliente", "error")
            } else {
              this.$swal("Borrado", "Se completo satisfactoriamente la cita", "success")
              this.close()
              this.iniciar()
            }
          }
        })
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally {
        this.loading = false
      }
    },
    closeService(){
      this.$nextTick(() => {
        this.editedService = Object.assign({}, this.defaultService);
      });
    },
    pushService() {
      let obj = {}
      this.editedService["item"] = this.valueItem
      obj["item"] = this.valueItem
      obj["service"] = this.editedService["service"]["id"]
      obj["assigned"] = this.editedService["assigned"]["id"]
      this.services.push(this.editedService)
      this.servicesSave.push(obj)
      this.editedItem.total += parseInt(this.editedService["service"]["sale_price"])
      this.valueItem += 1
      this.closeService()
    },
    async deleteItem(item){
      let i = this.services.indexOf(item)
      this.editedItem.total -= parseInt(item["service"]["sale_price"])
      this.services.splice(i,1)
      this.servicesSave.splice(i,1)
    },
    async save(){
      const cp = this.editedItem;
      cp["enterprise"] = localStorage.getItem("SucursalId")
      cp["allocator"] = this.editedItem["allocator"]["id"]
      cp["client"] = this.editedItem["client"]["id"]
      cp["services_date"]=this.servicesSave
      cp["appointment_date"]=`${this.date} ${this.time}`
      console.log(cp)

      try {
        this.loading = true
        let r = await this.api.saveBooking(cp)
        if (!r.ok){
          this.$swal("Error", Object.values(r)[0][0],"error")
        }
        else{
          this.$swal("Guardado","Cita Guardada Satisfactoriamente", "success")
          this.close()
          this.iniciar()
        }
        // this.mensaje("Guardado Satisfactoriamente")
      } catch (error) {
        alert(error)
        // this.mensaje(error,"Error","error")
      } finally{
        this.loading = false
      }
    },
  },
  created() {
    this.iniciar();
  },
};
</script>