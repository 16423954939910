import Api from "../Api"

export class ApiBook extends Api{
    constructor(){
        super()
    }

    async getBookingP(params, id=-1){
        return await super.getParams("reservas/reservas", id, params)
    }

    async getBookingPaginator(params, id=-1){
        return await super.getParamsPaginator("reservas/reservas", id, params)
    }

    async saveBooking(obj){
        return await super.save("reservas/reservas", obj)
    }

    async delBooking(id){
        return await super.delete("reservas/reservas", id)
    }
    async completeBooking(obj){
        return await super.save("reservas/completed", obj)
    }
}