import Api from "../Api"

export class ApiInv extends Api{
    constructor(){
        super()
    }

    async getCategorias(id=-1){
        return await super.get("inventario/categorias", id)
    }

    async saveCategorias(obj){
        return await super.save("inventario/categorias", obj)
    }

    async delCategorias(id){
        return await super.delete("inventario/categorias", id)
    }

    async getProductos(id=-1){
        return await super.get("inventario/productos", id)
    }
    
    async getProductosP(params, id=-1){
        return await super.getParams("inventario/productos", id, params)
    }

    async saveProductos(obj){
        return await super.save("inventario/productos", obj)
    }

    async delProductos(id){
        return await super.delete("inventario/productos", id)
    }

    async getServicios(id=-1){
        return await super.get("inventario/servicios", id)
    }
    
    async getServiciosP(params, id=-1){
        return await super.getParams("inventario/servicios", id, params)
    }

    async saveServicios(obj){
        return await super.save("inventario/servicios", obj)
    }

    async delServicios(id){
        return await super.delete("inventario/servicios", id)
    }
    async getIngresos(id=-1){
        return await super.get("inventario/ingresos", id)
    }

    async getIngresosP(params, id=-1){
        return await super.getParams("inventario/ingresos", id, params)
    }

    async saveIngresos(obj){
        return await super.save("inventario/ingresos", obj)
    }

    async getIngresosDetail(id=-1){
        return await super.get("inventario/ingresos", id)
    }

    async getEgresos(id=-1){
        return await super.get("inventario/egresos", id)
    }

    async getEgresosP(params, id=-1){
        return await super.getParams("inventario/egresos", id, params)
    }

    async saveEgresos(obj){
        return await super.save("inventario/egresos", obj)
    }

    async getEgresosDetail(id=-1){
        return await super.get("inventario/egresos", id)
    }

    async getInventario(id=-1){
        return await super.get("inventario/inventario", id)
    }

    async getInventarioP(params, id=-1){
        return await super.getParams("inventario/inventario", id, params)
    }
    
    async getExcelInv(params){
        return await super.getFile("inventario/informe-inventario-excel", params)
    }
}